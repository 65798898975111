import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from '$components/SEO';
import scalingSize from '$utils/scalingSize';
import { WrapMax } from '$components/Wraps';
import Paragraph from '$components/Paragraph';
import Mark from '$components/Mark';
import Button from '$components/Button';
import Heading1 from '$components/Heading1';
import Heading2 from '$components/Heading2';
import FadeInSection from '$components/FadeInSection';
import { Color } from '$utils/Color';

const Outer = styled.section`
  ${scalingSize('margin-top', 40, 88)};
  ${scalingSize('margin-bottom', 32, 72)}
`;

const Inner = styled(WrapMax)`
  display: grid;
  grid-template-columns: 100%;
  grid-gap: 1em;

  @media screen and (min-width: 860px) {
    grid-gap: 1.75em;
  }
`;

const StyledA = styled.a`
  color: var(--color-slate);
  border-bottom: 1px solid var(--color-citrus);
  transition: border 0.2s ease;

  &:hover {
    border-bottom: 1px solid var(--color-slate);
  }
`;

const Content = styled.div`
  max-width: var(--max-width-xs-wrap);
  @media screen and (min-width: 860px) {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
`;

const Links = styled.div`
  margin-bottom: 1.125rem;
`;

const NotFoundPage: React.FC = () => (
  <Outer>
    <FadeInSection>
      <Inner>
        <SEO title="UX-designbyrå i Sundsvall, Stockholm och världen." />

        <Heading1 notFoundPage>
          Oops, den här sidan <Mark color={Color.citrus4}>finns inte</Mark>
        </Heading1>

        {/* <Heading2 notFoundPage>Kanske har den blivit osynlig?</Heading2> */}
        <Content>
          <Heading2 notFoundPage>Kolla om du hittar det du söker här istället:</Heading2>
          <Links>
            <Button to="/artiklar/" theme="ghost" size="small" showArrow>
              Våra artiklar
            </Button>
            <Button to="/case/" theme="ghost" size="small" showArrow>
              Våra kundcase
            </Button>
            <Button to="/kontakta-oss/" theme="ghost" size="small" showArrow>
              Kontaktuppgifter till oss som jobbar här
            </Button>
          </Links>
          <Paragraph>
            Eller så hör du av dig till <StyledA href="mailto:hej@osynlig.se">hej@osynlig.se</StyledA> så hjälper vi
            dig!
          </Paragraph>
        </Content>
      </Inner>
    </FadeInSection>
  </Outer>
);

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
